<template>
  <div>
    <phone-btn
      :icon-props="iconProps"
      :phone-number="value"
      :hide-btn-on-empty=true
      small
    />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  components: {
    PhoneBtn: () => import('@/components/phone-btn')
  },
  computed: {
    iconProps () {
      return { small: true }
    }
  },
  props: {
    value: {
      type: String
    }
  }
}
</script>
